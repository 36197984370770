@font-face {
  src: url('fonts/SpectrumSans-Medium_522384009.ttf');
  font-family: SpectrumSans;
}

@font-face {
  src: url(./fonts/SpectrumSans_A_Rg.ttf);
  font-family: SpectrumSans-Regular;
}

.active-user {
  text-align: center;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #00bf1f;
}

.inactive-user {
  text-align: center;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #9ba9bd;
}

#report-side-bar-menu .custom-menu{
  display: none;
  position: absolute;
  z-index: 10;
  left: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  top: 0px;
  background: white;
}

#report-side-bar-menu:hover .custom-menu{
  display: block;
}